@tailwind base;
@tailwind components;
@tailwind utilities;
@import url(https://fonts.googleapis.com/css2?family=Mada:wght@200;300;400;500;600;700;800;900&display=swap);
body,
main {
  scroll-snap-type: mandatory;
  scroll-snap-points-y: repeat(100vh);
  scroll-snap-align: center;
}
@font-face {
  font-family: Luimp;
  src: url(./fonts/LuimpItalic.woff2) format("woff2");
  src: url(./fonts/Luimp.woff2) format("woff2");
  font-weight: 300;
  font-display: swap;
}
.pop-in {
  animation: 3s fadeIn;
}
.clip-circle {
  animation: 2s polygon;
}
@keyframes polygon {
  100% {
    clip-path: polygon(
      0 0,
      50% 0,
      100% 0,
      100% 50%,
      100% 100%,
      50% 100%,
      0 100%,
      0 50%
    );
  }
  0% {
    clip-path: polygon(
      50% 50%,
      50% 25%,
      50% 50%,
      75% 50%,
      50% 50%,
      50% 75%,
      50% 50%,
      25% 50%
    );
  }
}
.bluewash {
  filter: sepia(80%) hue-rotate(210deg) saturate(600%);
}
.stickyScroll {
  overflow-anchor: none;
}
body {
  width: 100vw;
  overflow-x: clip;
  margin: 0;
  background-color: #000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-snap-type: y mandatory;
  user-select: none;
}
main {
  scroll-snap-type: y mandatory;
}
::-webkit-scrollbar {
  display: none;
}

.gradient {
  background: #1b1b1c;
  background: -moz-linear-gradient(287deg, #1b1b1c 0, #321365 100%);
  background: -webkit-linear-gradient(287deg, #1b1b1c 0, #321365 100%);
  background: linear-gradient(287deg, #1b1b1c 0, #321365 100%);
}
.Mathworks {
  background-image: linear-gradient(
    50deg,
    hsl(226deg 43% 28%) 0,
    hsl(217deg 53% 26%) 21%,
    hsl(308deg 10% 28%) 42%,
    hsl(7deg 42% 30%) 63%,
    hsl(351deg 71% 26%) 84%,
    hsl(309deg 52% 22%) 100%
  );
}
.Staybook {
  background-image: linear-gradient(
    70deg,
    hsl(202deg 84% 17%) 0,
    hsl(201deg 85% 18%) 31%,
    hsl(189deg 100% 16%) 61%,
    hsl(180deg 99% 15%) 85%,
    hsl(181deg 100% 15%) 97%,
    hsl(181deg 100% 15%) 100%
  );
}
.LTV {
  background-image: linear-gradient(
    245deg,
    hsl(78deg 11% 69%) 0,
    hsl(206deg 14% 82%) 55%,
    hsl(1deg 7% 90%) 85%,
    hsl(96deg 2% 90%) 97%,
    hsl(180deg 1% 83%) 100%
  );
}
.Respct {
  background-image: linear-gradient(
    65deg,
    hsl(232deg 41% 15%) 0,
    hsl(238deg 39% 18%) 17%,
    hsl(254deg 44% 24%) 33%,
    hsl(268deg 56% 31%) 50%,
    hsl(275deg 61% 35%) 67%,
    hsl(278deg 62% 32%) 83%,
    hsl(280deg 59% 23%) 100%
  );
}
.Tunnel {
  background-image: linear-gradient(
    130deg,
    hsl(223deg 68% 6%) 0,
    hsl(224deg 50% 22%) 3%,
    hsl(234deg 39% 41%) 6%,
    hsl(248deg 46% 56%) 11%,
    hsl(255deg 49% 55%) 17%,
    hsl(262deg 51% 54%) 25%,
    hsl(266deg 45% 49%) 35%,
    hsl(266deg 46% 41%) 48%,
    hsl(266deg 47% 33%) 64%,
    hsl(249deg 41% 28%) 79%,
    hsl(229deg 43% 21%) 91%,
    hsl(221deg 34% 14%) 100%
  );
}
.workBg {
  background-image: radial-gradient(at -3.1% 101.3%, #b778c9 0, transparent 50%),
    radial-gradient(at 75.3% 58.4%, #687f41 0, transparent 50%),
    radial-gradient(at 75% 38.9%, #4f7696 0, transparent 50%),
    radial-gradient(at 30.8% 66.4%, #d18e00 0, transparent 50%),
    radial-gradient(at 16.1% 10.7%, #9f73b8 0, transparent 50%),
    radial-gradient(at 93.8% 14.4%, #9f73b8 0, transparent 50%),
    radial-gradient(at 73.4% 86.5%, #d18e00 0, transparent 50%);
}
.projectBg {
  background-image: radial-gradient(at 1.3% 2.5%, #0d1848 0, transparent 50%),
    radial-gradient(at 38.3% 84.6%, #3958c0 0, transparent 50%),
    radial-gradient(at 86.1% 0.5%, #bc78ec 0, transparent 50%),
    radial-gradient(at 0.3% 98.3%, #0d1848 0, transparent 50%),
    radial-gradient(at 64.8% 80.1%, #37245a 0, transparent 50%),
    radial-gradient(at 93.8% 83%, #37245a 0, transparent 50%),
    radial-gradient(at 28.7% 29.3%, #2a4692 0, transparent 50%),
    radial-gradient(at 83.7% 88.2%, #7f499c 0, transparent 50%);
}
.communityBg {
  background-image: radial-gradient(at 45.9% 6.3%, #66228a 0, transparent 50%),
    radial-gradient(at 10.3% 18.8%, #5e1b58 0, transparent 50%),
    radial-gradient(at 11.7% 83.8%, #812c66 0, transparent 50%),
    radial-gradient(at 48.3% 99.8%, #c0436b 0, transparent 70%),
    radial-gradient(at 77.9% 11.4%, #5f1f74 0, transparent 60%),
    radial-gradient(at 98.9% 88.8%, #812c66 0, transparent 50%);
}
.mentorshipBg {
  background-image: radial-gradient(at 32.1% 77%, #5f1f74 0, transparent 50%),
    radial-gradient(at 57.8% 72.1%, #812c66 0, transparent 50%),
    radial-gradient(at 8% 6.2%, #af6abd 0, transparent 50%),
    radial-gradient(at 28.6% 26%, #6622ae 0, transparent 50%),
    radial-gradient(at 67% 45.9%, #ad2384 0, transparent 50%),
    radial-gradient(at 8.1% 85.2%, #c17fbf 0, transparent 50%),
    radial-gradient(at 101.7% 3%, #c17fbf 0, transparent 50%),
    radial-gradient(at 100.7% 86.6%, #c17fbf 0, transparent 50%);
}
.awardsBg {
  background-image: radial-gradient(at 8% 6.2%, #af6abd 0, transparent 50%),
    radial-gradient(at 8.1% 85.2%, #c17fbf 0, transparent 50%),
    radial-gradient(at 101.7% 3%, #c17fbf 0, transparent 50%),
    radial-gradient(at 100.7% 86.6%, #c17fbf 0, transparent 50%),
    radial-gradient(at 68.3% 41.5%, #009367 0, transparent 50%),
    radial-gradient(at 18.3% 37%, #7181a6 0, transparent 50%),
    radial-gradient(at 29% 85.1%, #009371 0, transparent 50%),
    radial-gradient(at 68.8% 85.2%, #009371 0, transparent 50%);
}
@keyframes fadeIn {
  0% {
    translate: 0 7rem;
  }
  100% {
    translate: 0 0rem;
  }
}
